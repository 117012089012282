import { reactive, ref } from "vue";
import { feeList } from "@/api/info/payRecordApi";
import useMessage from "@/hooks/useMessage";
const useFreeRecord = () => {
  const { showErr } = useMessage();
  const loading = ref(false);
  const dataList = reactive({ list: [] });
  const total = ref(0);

  const payStates = ref([
    { value: 0, label: "待支付" },
    { value: 2, label: "免费-错误免单" },
  ]);

  //{0:未知,1:蓝,2:黄,3:黑,4:白,5:绿}'
  const plateColors = ref([
    { value: 0, label: "未知" },
    { value: 1, label: "蓝牌" },
    { value: 2, label: "黄牌" },
    { value: 3, label: "黑牌" },
    { value: 4, label: "白牌" },
    { value: 5, label: "绿牌" },
  ]);

  const freeDetailVo = reactive({
    parkingRecordId: "",
    freeReason: "",
    freedBy: "",
    freeTime: "",
    remark: "",
  });

  const columns = [
    {
      title: "序号",
      align: "center",
      width: "80px",
      customRender: ({ index }) => {
        return index + 1;
      },
    },
    {
      title: "车场名称",
      align: "center",
      dataIndex: "parkingLotName",
    },
    {
      title: "泊位号",
      align: "center",
      dataIndex: "slotNum",
    },
    {
      title: "车牌号",
      align: "center",
      dataIndex: "plateNumber",
      slots: { customRender: "plateNumber" },
    },
    {
      title: "进场时间",
      align: "center",
      width: "200px",
      dataIndex: "inTime",
    },
    {
      title: "离场时间",
      align: "center",
      width: "200px",
      dataIndex: "outTime",
    },
    {
      title: "停车时长",
      align: "center",
      dataIndex: "parkingTime",
      slots: { customRender: "parkingTime" },
    },
    {
      title: "支付状态",
      align: "center",
      dataIndex: "payState",
      slots: { customRender: "payState" },
    },
    {
      title: "应付金额（元）",
      align: "center",
      dataIndex: "shouldPayAmount",
      slots: { customRender: "shouldPayAmount" },
    },
    {
        title: "已支付（元）",
        align: "center",
        dataIndex: "paidAmount",
        slots: { customRender: "paidAmount" },
    },
    {
      title: "操作",
      align: "center",
      slots: { customRender: "operation" },
    },
  ];
  const money = m => {
    if (typeof m !== "undefined") {
      return (m / 100).toFixed(2);
    }
    return "";
  };
  const timeF = m => {
    if (typeof m !== "undefined") {
      return (m / 60).toFixed(0);
    }
    return "";
  };
  const getParkingTime = t => {
    if (typeof t !== "undefined") {
      var h = ((t - (t % 3600)) / 3600).toFixed(0);
      var s = ((t % 3600) / 60).toFixed(0);
      return h == 0 ? s + "分钟" : h + "小时" + (s == 0 ? "" : s + "分钟");
    }
    return "";
  };
  const loadData = async (page, pageSize) => {
    loading.value = true;
    let p = {
      parkingLotId: params.parkingLotId,
      plateNumber: params.plateNumber,
      plateColor: params.plateColor,
      slotNum: params.slotNum,
      payState: params.payState, //支付状态 0:待支付,2:支付完成
      carInArr: params.carInArr ? params.carInArr.toString() : [],
      carOutArr: params.carOutArr ? params.carOutArr.toString() : [],
    };
    try {
      let res = await feeList(p, page, pageSize);
      dataList.list = res.data;
      total.value = res.total;
      loading.value = false;
    } catch (e) {
      loading.value = false;
      await showErr(e);
    }
  };

  const params = reactive({
    parkingLotId: null,
    plateNumber: "",
    plateColor: null,
    slotNum: "",
    payState: null, //支付状态 0:待支付,2:支付完成
    carInArr: [],
    carOutArr: [],
  });
  return {
    loading,
    params,
    dataList,
    total,
    payStates,
    plateColors,
    freeDetailVo,
    columns,
    loadData,
    money,
    timeF,
    getParkingTime,
  };
};
export default useFreeRecord;
